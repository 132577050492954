//备机申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="申请人：" prop="proposerCode">
            <SelectProposer
              :code.sync="params.proposerCode"
              :name.sync="params.proposerName"
              @change="proposerChange"
              :disabled="!isCreate"
            />
          </el-form-item>
          <el-form-item label="申请人部门：" prop="applyDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.applyDepartmentCode"
              :name.sync="params.applyDepartmentName"
              disabled
            />
          </el-form-item>
          <el-form-item label="日期：" prop="applyTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.applyTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="客户名称(全称)：" prop="clientCode">
            <SelectCustomer
              :code.sync="params.clientCode"
              :name.sync="params.clientName"
            />
          </el-form-item>
          <el-form-item label="收货人：" prop="consignee">
            <el-input
              v-model="params.consignee"
              placeholder="请输入收货人"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="预计归还日期：" prop="expectedReturnTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.expectedReturnTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
              }"
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item
            label="联系电话："
            prop="consigneeMobile"
            style="flex: inherit; width: 33.33%"
          >
            <el-input
              v-model="params.consigneeMobile"
              placeholder="请输入联系电话"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货地址：" prop="shippingAddress">
            <el-input
              v-model="params.shippingAddress"
              placeholder="请输入收货地址"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="申请理由：" prop="applyReason">
          <el-input
            v-model="params.applyReason"
            placeholder="申请理由"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <div class="cm-flex-line">
          <el-form-item label="加急快递：" prop="urgentExpress">
            <el-switch
              v-model="params.urgentExpress"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <!-- <el-form-item label="转出仓库：" prop="outWarehouseCode">
            <SelectWarehouse
              :code.sync="params.outWarehouseCode"
              :name.sync="params.outWarehouseName"
            />
          </el-form-item> -->
          <el-form-item label="转入仓库：" prop="inWarehouseCode">
            <SelectWarehouse
              :code.sync="params.inWarehouseCode"
              :name.sync="params.inWarehouseName"
              @change="changeWarehouse"
            />
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input
              v-model="params.remark"
              placeholder="请输入备注"
              type="textarea"
              maxlength="255"
              show-word-limit
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">申请物品清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="料号">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="物品名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="申请数量" width="100">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="转入货位">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.inPositionCode'"
                :rules="rules.inPositionCode"
              >
                <SelectGoodsLocation
                  type="in"
                  ref="SelectGoodsLocation"
                  :code.sync="params.detailUpdateInputs[$index].inPositionCode"
                  :name.sync="params.detailUpdateInputs[$index].inPositionName"
                  :params="{
                    warehouseCode: params.inWarehouseCode
                  }"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <!-- <el-table-column header-align="center" label="转出货位">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.outPositionCode'"
              >
                <SelectGoodsLocation
                  :ref="'SelectGoodsLocationOut' + $index"
                  :code.sync="params.detailUpdateInputs[$index].outPositionCode"
                  :name.sync="params.detailUpdateInputs[$index].outPositionName"
                  :maxNum.sync="params.detailUpdateInputs[$index].maxOutNum"
                  :params="{
                    inventoryCode: params.detailUpdateInputs[$index].code,
                    warehouseCode: params.outWarehouseCode
                  }"
                />
              </el-form-item>
            </template>
          </el-table-column> -->
          <el-table-column header-align="center" label="备注">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.remark'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].remark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label=" ">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
      </el-form>
    </div>
    <FlowableStep
      :orderType="from === 'partsApply' ? '2' : '1'"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import applyMixins from '../../mixins/applyMixins'
export default {
  name: 'StandByApply',
  props: ['from'],
  mixins: [applyMixins],
  components: { FlowableStep },
  data() {
    return {
      rules: {
        proposerCode: [{ required: true, message: '请选择申请人' }],
        applyDepartmentCode: [{ required: true, message: '请选择申请人部门' }],
        applyTime: [{ required: true, message: '请选择申请日期' }],
        clientCode: [{ required: true, message: '请选择客户名称' }],
        consignee: [{ required: true, message: '请输入收货人' }],
        expectedReturnTime: [{ required: true, message: '请选择预计归还日期' }],
        consigneeMobile: [
          { required: true, message: '请输入联系电话' },
          { min: 11, max: 11, message: '请输入正确的手机号' }
        ],
        shippingAddress: [{ required: true, message: '请输入收货地址' }],
        applyReason: [{ required: true, message: '请输入申请理由' }],
        // outWarehouseCode: [{ required: true, message: '请选择转出仓库' }],
        inWarehouseCode: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let outWarehouseCode = this.params.outWarehouseCode
              if (!value) {
                callback('请选择转入仓库')
              } else if (outWarehouseCode === value) {
                callback('转入仓库和转出仓库不能相同')
              } else {
                callback()
              }
            }
          }
        ],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let index = rule.field?.split('.')[1]
              let maxOutNum = this.params.detailUpdateInputs[index].maxOutNum
              if (!value || Number(value) === 0) {
                callback('输入正整数')
              } else if (value && Number(value) > Number(maxOutNum)) {
                callback(`最多${maxOutNum}`)
              } else if (value && Number(value) > 99999) {
                callback('最多99999')
              } else {
                callback()
              }
            }
          }
        ],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        inPositionCode: [
          {
            required: false,
            validator: (rule, value, callback) => {
              try {
                const $elem = this.$refs['SelectGoodsLocation']
                const list = $elem?.list || []
                if (list.length > 0 && !value) {
                  callback('请选择货位')
                } else {
                  callback()
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        ]
      },
      params: {
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {
        WAREHOUSE_IN: '' // 条件参数值
      }
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    changeWarehouse(data) {
      this.variables['WAREHOUSE_IN'] = data.cwhCode
    },
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        let res
        if (this.from === 'partsApply') {
          res = await this.$api.apply.partsApplyInfo({ orderCode })
          //配件
        } else {
          //备机
          res = await this.$api.apply.standByApplyInfo({ orderCode })
        }
        if (res) {
          const { orderResult, detailResults } = res
          let params = {
            ...orderResult,
            detailUpdateInputs: detailResults,
            auditors: {}
          }
          this.params = params
        }
      } catch (err) {
        console.log(err)
      }
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        num: '',
        code: '',
        name: '',
        remark: '',
        inPositionCode: '',
        inPositionName: '',
        outPositionName: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    submitEvent(submit) {
      if (this.loading) return

      let params = this.$utils.deepClone(this.params)
      if (params.applyTime) {
        params.applyTime = params.applyTime + ' 00:00:00'
      }
      if (params.expectedReturnTime) {
        params.expectedReturnTime = params.expectedReturnTime + ' 23:59:59'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs
      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          if (this.from === 'partsApply') {
            //配件
            this.$api.apply.partsApplySave(newParams).then(result => {
              this.$message.success('草稿保存成功')
            })
          } else {
            //备机
            this.$api.apply.standByApplySave(newParams).then(result => {
              this.$message.success('草稿保存成功')
            })
          }
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条物品清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              if (this.from === 'partsApply') {
                //配件
                await this.$api.apply.partsApplySave(newParams)
              } else {
                //备机
                await this.$api.apply.standByApplySave(newParams)
              }
              //提交成功
              this.submitSuccess(
                this.from === 'partsApply' ? '/#/partsApply' : '/#/standByApply'
              )
            } finally {
              this.loading = false
            }
          }
        })
      }
    },
    //申请人改变
    proposerChange(a) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(a.cdepCode)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
